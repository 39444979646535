import store from '@/store'

import { HTTP } from './api_main'

export default {
  ////////////////
  /// External access
  /**
   * Получает настройки web доступа от бекенда.
   * @returns {Promise<webAccessSettings>} Список сертификатов.
   */
  getWebAccessSettings() {
    return HTTP.get('settings/web-access-settings/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Получает настройки web доступа от бекенда.
   * @param {*} webAccessSettings 
   */
  setWebAccessSettings(webAccessSettings) {
    return HTTP.put('settings/web-access-settings/', webAccessSettings)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  ////////////////
  /// Certificates
  /**
   * Получает список сертификатов с бекенда.
   * @returns {Promise<certificate[]>} Список сертификатов.
   */
  getCertificates() {
    return HTTP.get('certificates/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на удаление x509 сертификата.
   * @param {certificate} certificate 
   */
  deleteCertificate(certificate) {
    return HTTP.delete(`certificates/${certificate.name}/`)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на генерацию self-signed сертификата.
   * @param {certRequest} certRequest Параметры запроса сертификата.
   */
  generateSelfSignCertificate(certRequest) {
    console.log(certRequest)
    return HTTP.post('certificates/generate_self_signed_certificate/', certRequest)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  /**
   * Отправляет запрос на генерацию x509 csr.
   * @param {certRequest} certRequest Параметры запроса сертификата.
   */
  generateCsr(certRequest) {
    return HTTP.post('certificates/generate_csr/', certRequest)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  /**
   * Загружает файлы сертификата на сервер.
   * @param {uploadCertRequest} uploadCertRequest 
   */
  uploadNewCertificate(uploadCertRequest) {
    return HTTP.post('certificates/upload_new_cert/', uploadCertRequest)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  /**
   * Загружает x509 сертификат относящийся к ранее созданному csr.
   * @param {uploadCertCorCsrRequest} uploadCertCorCsrRequest 
   */
  uploadCertificateCorCsr(uploadCertCorCsrRequest) {
    return HTTP.post('certificates/upload_cert_cor_csr/', uploadCertCorCsrRequest, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  ////////////////
  /// Trigger
  getAllTriggersWithRelatedActions() {
    return HTTP.get(`trigger/list_triggers_with_actions/`)
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  createTrigger(newTrigger) {
    return HTTP.post('trigger/', newTrigger)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  deleteTrigger(triggerName) {
    return HTTP.delete(`trigger/${triggerName}/delete_trigger_with_actions/`)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  createActionRelatedTrigger(relation) {
    return HTTP.post('action/create_action_with_relation/', relation)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  deleteAction(actionName) {
    return HTTP.delete(`action/${actionName}/`)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  ////////////////
  /// Update
  uploadUpdateFile(formData) {
    return HTTP.post('update/upload/', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: function(progressEvent) {
        let uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        store.commit('updateUploadProgress', uploadProgress)
      },
    })
    .then(response => {
      return response.data
    })
    .finally(() => {
      store.commit('updateUploadProgress', 0)
    })
  },
  checkUpdate(update) {
    return HTTP.post('update/check/', update)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  installUpdate(update) {
    return HTTP.post('update/install/', update)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  restoreUpdate(update) {
    return HTTP.post('update/restore/', update)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  deleteUpdate(update) {
    return HTTP.post('update/delete/', update)
      .then( response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 400) {
          console.log(error.response.data)
          throw error
        }
        console.log(error)
      })
  },
  ////////////////
  /// Authentication
  login(credentials) {
    return HTTP.post('auth/token/login/', credentials)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error.response.data
      }
      console.log(error)
    })
  },
  logout() {
    return HTTP.post('auth/token/logout/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  resetPassword(passwords) {
    return HTTP.post('auth/users/set_password/', passwords)
    .then( response => {
      
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error.response.data
      }
      console.log(error)
    })
  },
  /// Authorization
  getUserProfile() {
    return HTTP.get(`/auth/users/me/`)
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  /// Users Management
  getUsers() {
    return HTTP.get(`/auth/users/`)
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  changeUserPassword(id, passwords) {
    console.log(passwords)
    return HTTP.put(`auth/users/${id}/reset_user_password/`, passwords)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error.response.data
      }
      console.log(error)
    })
  },
  ////////////////
  ///Logs
  getLogs(service) {
    return HTTP.get(`/statistic/logs/${service}/`)
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  ////////////////
  /// System Settings
  ////////////////
  // License
  getLicenseInfo() {
    return HTTP.get(`settings/license/`)
    .then( response => {
      return response.data
    })
  },
  uploadLicenseFile(formData) {
    return HTTP.post('settings/license/', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
    .then(response => {
      return response.data
    })
  },
  // System Time
  getLightmasterTimezone() {
    return HTTP.get('settings/time/timezone/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  updateLightmasterTimezone(newTimezone) {
    let data = {timezone: newTimezone}
    return HTTP.put('settings/time/timezone/', data)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  getTimeMode() {
    return HTTP.get('settings/time/time_mode/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  updateTimeMode(newTimeMode) {
    let data = {time_mode: newTimeMode}
    return HTTP.put('settings/time/time_mode/', data)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  getSystemTime() {
    return HTTP.get('settings/time/system_time/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  setSystemTime(newTime) {
    return HTTP.post('settings/time/system_time/', newTime)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  getNTPServers () {
    return HTTP.get('settings/time/ntp_servers/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  setNTPServers (newNTPServers) {
    return HTTP.post('settings/time/ntp_servers/', newNTPServers)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  getNTPSyncStatus () {
    return HTTP.get('settings/time/ntp_servers/check_sync_status/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  getCurrentNTPServer () {
    return HTTP.get('settings/time/ntp_servers/current_server/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  // Configs
  getConfigsForSaving() {
    return HTTP.get('settings/save_configs/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  ////////////////
  /// Network
  // Interfaces
  /**
   * Получает настройки wired интерфейса.
   * @returns {Promise<webAccessSettings>} Настройки wired интерфейса.
   */
  getIfaceWired() {
    return HTTP.get('settings/network/interfaces/wired/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      } else if (error.response.status === 404) {
        console.log('vvv')
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Получает настройки modem интерфейса.
   * @returns {Promise<webAccessSettings>} Настройки modem интерфейса.
   */
  getIfaceModem() {
    return HTTP.get('settings/network/interfaces/modem/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      } else if (error.response.status === 404) {
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на изменение настроек ip адресации для wired интерфейса.
   * @param {newIpCredential} newIpCredential Новые настройки ip адресации.
   */
  updateWiredIpCredential(newIpCredential) {
    return HTTP.post('settings/network/interfaces/wired/set_ip_credential/', newIpCredential)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на изменение настроек ip адресации для modem интерфейса.
   * @param {newIpCredential} newIpCredential Новые настройки ip адресации.
   */
  updateModemIpCredential(newIpCredential) {
    return HTTP.post('settings/network/interfaces/modem/set_ip_credential/', newIpCredential)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на изменение настроек dns для modem интерфейса.
   * @param {newDnsCredential} newDnsCredential Новые настройки dns.
   */
  updateWiredDnsCredential(newDnsCredential) {
    return HTTP.post('settings/network/interfaces/wired/set_dns_credential/', newDnsCredential)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на изменение настроек dns для modem интерфейса.
   * @param {newDnsCredential} newDnsCredential Новые настройки dns.
   */
  updateModemDnsCredential(newDnsCredential) {
    return HTTP.post('settings/network/interfaces/modem/set_dns_credential/', newDnsCredential)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  /**
   * Отправляет запрос на изменение настроек apn для modem интерфейса.
   * @param {newApnCredential} newApnCredential Новые настройки apn.
   */
   updateModemApnCredential(newApnCredential) {
    return HTTP.post('settings/network/interfaces/modem/set_apn_credential/', newApnCredential)
    .then( response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error
      }
      console.log(error)
    })
  },
  getPlayingInfo() {
    return HTTP.get('player/playing_status/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  getVersions() {
    return HTTP.get('version/').then( response => {
      return response.data
    })
  },
  // Backup and restore
  downloadBackupFile() {
    return HTTP.get('db_backup/', {responseType: 'blob'})
    .then( response => {
      return response
    })
    .catch(error => {
      console.log(error)
    })
  },
  downloadDebugInfo() {
    return HTTP.get('debug_info/', {responseType: 'blob'})
    .then( response => {
      return response
    })
    .catch(error => {
      console.log(error)
    })
  },
  uploadBackupFile(formData) {
    return HTTP.post('db_backup/', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
    .then(response => {
      return response.data
    })
  },
  uploadCueGifs(formData) {
    return HTTP.post('cue/upload_gifs/', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
      },
    })
    .then(response => {
      return response.data
    })
  },
  uploadCueFiles(formData) {
    return HTTP.post('cue/upload_files/', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: function(progressEvent) {
        let uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        store.commit('updateUploadProgress', uploadProgress)
      },
    })
    .then(response => {
      return response.data
    })
    .finally(() => {
      store.commit('updateUploadProgress', 0)
    })
  },
  deleteCueFile(cueId) {
    return HTTP.delete(`cue/${cueId}/`)
    .then(response => {
      return response.status == 204
    })
  },
  loadUniversesSettings() {
    return HTTP.get('universes-settings/')
    .then( response => {
      return response.data
    })
  },
  addUniverse(universe) {
    return HTTP.post('universes-settings/', universe)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error.response.data
      }
      console.log(error)
    })
  },
  editUniverse(universe) {
    return HTTP.put(`universes-settings/${universe.number}/`, universe)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error.response.data
      }
      console.log(error)
    })
  },
  deleteUniverse(universe) {
    return HTTP.delete(`universes-settings/${universe.number}/`)
    .then(response => {
      return response.status == 204
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
        throw error.response.data
      }
      console.log(error)
    })
  },
  loadArtNetDevicesSettings() {
    return HTTP.get('device-config/')
    .then( response => {
      return response.data
    })
  },
  addArtNetDevice(device) {
    console.log(device)
    return HTTP.post('device-config/', device)
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  editArtNetDevice(device) {
    return HTTP.put(`device-config/${device.name}/`, device)
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  deleteArtNetDevice(deviceName) {
    return HTTP.delete(`device-config/${deviceName}/`)
    .then(response => {
      return response.status == 204
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      console.log(error)
    })
  },
  loadSettings() {
    return HTTP.get('settings/')
    .then( response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  saveSetting (setting) {
    return HTTP.put(`settings/${setting.key}/`, setting)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  //////
  // Scheduler
  getEvents() {
    return HTTP.get('scheduler/', )
    .then(response => {
      return response.data
    })
  },
  getRelatedEvents(playlistId) {
    return HTTP.get('scheduler/', { params: { search: playlistId } })
    .then(response => {
      return response.data
    })
  },
  addEvent(event) {
    return HTTP.post('scheduler/', event)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error.response)
    })
  },
  updateEvent(event) {
    return HTTP.put(`scheduler/${event.id}/`, event)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error.response)
    })
  },
  deleteEvent(id) {
    return HTTP.delete(`scheduler/${id}/`)
    .then(response => {
      return response.status == 204
    })
    .catch(error => {
      console.log(error.response)
    })
  },
  //Cues
  loadCues() {
    return HTTP.get('cue/')
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      console.log(error)
    })
  },
  
  refreshCues() {
    return HTTP.get('refresh-cue/')
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      console.log(error)
    })
  },
  //////
  // Playlists
  createPlaylist (playlist) {
    return HTTP.post('playlist/', playlist)
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  loadPlaylists() {
    return HTTP.get('playlist/')
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      console.log(error)
    })
  },
  savePlaylist(playlist) {
    return HTTP.put(`playlist/${playlist.id}/`, playlist)
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  deletePlaylist(id) {
    return HTTP.delete(`playlist/${id}/`)
    .then(response => {
      return response.status == 204
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      console.log(error)
    })
  },
  ////
  // Scenes
  createScene (scene) {
    return HTTP.post('scene/', scene)
    .then(response => {
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  deleteScene(id) {
    return HTTP.delete(`scene/${id}/`)
    .then(response => {
      return response.status == 204
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  updateScene(scene) {
    return HTTP.put(`scene/${scene.id}/`, scene)
    .then(response => {
      console.log(response.data)
      return response.data
    })
    .catch(error => {
      // if (error.status == 401){
      //   reject(error)
      // }
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    }) 
  },
  getPlayingEntities() {
    return HTTP.get('player/')
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
    })
  },
  play(entity, its_id) {
    let request = {"cmd": "play", "what_playing": entity, "its_id": its_id}
    return HTTP.post('player/', request)
    .then(response => {
      return response
    })
    .catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data)
      }
      console.log(error)
    })
  },
  pause(entity, its_id) {
    let request = {"cmd": "pause", "what_playing": entity, "its_id": its_id}
    return HTTP.put('player/', request)
    .then(response => {
      return response
    })
    .catch(error => {
      console.log(error)
    })
  },
  stop(entity, its_id) {
    let request = {"cmd": "stop", "what_playing": entity, "its_id": its_id}
    console.log(request)
    return HTTP.delete('player/', {data: request})
    .then(response => {
      return response
    })
    .catch(error => {
      console.log(error)
    })
  },
}
