export default {
  namespaced: true,
  state: {
    notifications: {
        error: [],
        warning: [],
    },
    pushNotifications: [],
  },
  mutations: {
    addNotification(state, notification) {
        let servility = notification.servility
        let msg = notification.msg
        state.notifications[servility].push(msg)
    },
    delNotification(state, notification) {
        let idx = state.notifications[notification.servility].findIndex((item) => item.msg == notification.msg)
        state.notifications[notification.servility].splice(idx, 1)
    },
    delPushNotification(state, index) {
        state.pushNotifications.splice(index, 1)
    },
    addPushNotification(state, notification) {
        state.pushNotifications.push(notification)
    },
    erasePushNotification(state) {
        state.pushNotifications = []
    }
  },
  actions: {
  },
  getters: {
    notificationsIsEmpty(state) {
      for (const servility in state.notifications) {
        if (state.notifications[servility].length !== 0) {
          return false
        }
      }
      return true
    },
  },
};