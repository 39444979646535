<template>
  <div>
    <v-app-bar
      :clipped-left="false"
      app
      dense
      flat
      color="white"
    >
      <v-app-bar-nav-icon  @click="changeDrawer()"/>
      <v-spacer />
      <div v-if="licenseErrorMsg" class="red--text" v-text="licenseErrorMsg"></div>
      <div v-else>
        <v-row>
        <span class="px-1" v-text="actualTime"></span>
        <span class="d-none d-sm-block px-1" v-text="timezone"></span>
        </v-row>
      </div>
      <v-spacer />
      <v-icon
        v-show="!notificationsIsEmpty"
        dense
        :color="messageColor"
        @click="pushAllNotifications"
      >
        mdi-alert-circle
      </v-icon>
      <div class="text-center">
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ username }} 
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changePassword()">
              <v-list-item-title>
                {{ $t('app_bar.change_password') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
                <v-list-item-title>
                  {{ $t('app_bar.logout') }}
                </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-toolbar-title></v-toolbar-title>
      <v-dialog
        v-model="changePasswordDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $t('app_bar.change_password_card.title') }}</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="changePasswordFormIsValid">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="changePasswordForm.currentPassword"
                      :error-messages="changePasswordFormErrors.current_password"
                      @input="changePasswordFormErrors.current_password=[]"
                      :label="$t('app_bar.change_password_card.curr_password_label')"
                      type="password"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="changePasswordForm.newPassword"
                      :error-messages="changePasswordFormErrors.new_password"
                      @input="changePasswordFormErrors.new_password=[]"
                      :label="$t('app_bar.change_password_card.new_pass_label')"
                      type="password"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="changePasswordForm.repeatedNewPassword"
                      :error-messages="changePasswordFormErrors.re_new_password"
                      @input="changePasswordFormErrors.re_new_password=[]"
                      :label="$t('app_bar.change_password_card.confirm_password_label')"
                      type="password"
                      :rules="[rules.required, rules.matchPasswords]"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="error-message red--text" v-if="changePasswordFormErrors.non_field_errors.length != 0">
                  <span v-for="(error, index) in changePasswordFormErrors.non_field_errors" :key="index"
                    v-text="error + ' '"
                  />
                </v-row>
              </v-container>
            </v-form>
            <small>*{{ $t('app_bar.change_password_card.indicate_required') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary--text"
              @click="cancelPasswordDialog"
            >
              {{ $t('app_bar.change_password_card.close_button') }}
            </v-btn>
            <v-btn
              color="primary--text"
              :disabled="!changePasswordFormIsValid"
              @click="savePasswordDialog"
            >
              {{ $t('app_bar.change_password_card.save_button') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <div>
      <v-snackbar v-for="(item, index) in pushNotifications" :key="index"
        v-model="showSnackbar"
        right
        top
        color="white"
        class="p-0"
        :timeout="snackbarTimeout"
        :style="{'margin-top':calcSnackbarMargin(index)}"
      >
        <v-alert
          outlined
          :type="item.servility"
          class="mb-0 px-2 py-2"
          border="left"
          @input="closeSnackbar(index)"
        >
          {{ item.msg }}
          <template v-slot:close="">
            <v-icon
              dense
              :color="messageColor"
              @click="closeSnackbar(index)"
            >mdi-close-thick</v-icon>
          </template>

        </v-alert>


      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { localizeRoute } from '@/i18n'

export default {
  data: () => ({
    showSnackbar: true,
    snackbarTimeout: 5000,
    changePasswordDialog: false,
    changePasswordFormIsValid: false,
    changePasswordFormErrors: {
      non_field_errors: [],
      current_password: [],
      new_password: [],
      re_new_password: [],
    },
    changePasswordFormErrorsDefault: {
      non_field_errors: [],
      current_password: [],
      new_password: [],
      re_new_password: [],
    },
    changePasswordForm: {
      newPassword: '',
      repeatedNewPassword: '',
      currentPassword: '',
    },
    changePasswordFormDefault: {
      newPassword: '',
      repeatedNewPassword: '',
      currentPassword: '',
    },
  }),
  computed: {
    rules () {
      return {
        required: value => !!value || this.$t('app_bar.change_password_card.rules.required'),
        matchPasswords: value => value === this.changePasswordForm.newPassword || this.$t('app_bar.change_password_card.rules.passwords_not_match')
      }
    },
    username() {
      return this.$store.state.userProfile.username
    },
    licenseErrorMsg() {
      if (this.$store.getters['license/isDemoLicense']) {
        return `${this.$t('app_bar.license_errors.no_license')}`
      } else if (this.$store.getters['license/isExpiredLicense']) {
        return `${this.$t('app_bar.license_errors.license_expired')}`
      } else if (this.$store.getters['license/licenseDayLeft'] <= 30) {
        return `${this.$t('app_bar.license_errors.license_will_expire')} ${this.$store.getters['license/licenseDayLeft']} ${this.$tc('app_bar.license_errors.license_days')}!`
      }
      return ''
    },
    messageColor() {
      let color = "green"
      if (this.$store.state.notification['notifications']['warning'].length !== 0) {
        color = "deep-orange darken-1"
      }
      return color
    },
    notificationsIsEmpty() {
      return this.$store.getters['notification/notificationsIsEmpty']
    },
    pushNotifications() {
      return this.$store.state.notification['pushNotifications']
    },
    notifications() {
      return this.$store.state.notification['notifications']
    },
    /**
     * Текущее серверное время
     * 
     * @returns {string} время в формате 'DD.MM.YYYY kk:mm'
     */
    actualTime() {
      return this.$store.getters['time/getActualTime']
    },
    /**
     * Серверная временная зона.
     * 
     * @returns {string} Временная зона.
     */
    timezone() {
      return this.$store.state.time.lightmasterTimezone
    }
  },
  methods: {
    closeSnackbar(index) {
      this.$store.commit('notification/delNotification', this.pushNotifications[index])
      this.$store.commit('notification/delPushNotification', index)
    },
    calcSnackbarMargin(index) {
        return (index*80) + 'px'
    },
    pushAllNotifications() {
      this.showSnackbar = true
      this.$store.commit('notification/erasePushNotification')
      for (const servility in this.notifications) {
        for (let msg of this.notifications[servility]) {
          this.$store.commit('notification/addPushNotification', {"servility": servility, "msg": msg})
        }
      }
      return true
    },
    changeDrawer() {
      this.$store.commit('changeDrawer')
    },
    logout() {
      this.$store.dispatch('logout')
      
      this.$router.push(localizeRoute({name: 'login'}))
    },
    changePassword() {
      this.changePasswordDialog = true
    },
    cancelPasswordDialog() {
      this.changePasswordDialog = false
      this.changePasswordForm = Object.assign({}, this.changePasswordFormDefault)
      this.changePasswordFormErrors = Object.assign({}, this.changePasswordFormErrorsDefault)
    },
    savePasswordDialog() {
      this.$store.dispatch('resetPassword', this.changePasswordForm)
      .then( () => {
        this.cancelPasswordDialog()
        this.logout()
      })
      .catch( errors => {
        Object.assign(this.changePasswordFormErrors, errors)
      })
    },
  },
  mounted () {
  },
}
</script>

<style>

</style>