import Api from "@/api/api";

export default {
  namespaced: true,
  state: {
    logs: {
      lm_player: [],
    },
  },
  mutations: {
    /**
     * Полностью обновить состояние логов.
     * @param {*} logs Новое состояние логов.
     */
    setLogs(state, logs) {
      state.logs = logs
    },
    /**
     * Обновить состояние логов добавив одну запись.
     * @param {string} logs Одна запись лога.
     */
    addLog(state, log) {
      if (!state.logs.lm_player.includes(log)) {
        this.logs.lm_player.shift()
        this.logs.lm_player.push(log)
      }
    }
  },
  actions: {
    /**
     * Получить список логов с сервера и обновить состояние.
     */
    async getLogs(context) {
      await Api.getLogs('lm_player').then((logs) => {
      context.commit('setLogs', logs)
      })
    },
    /**
     * Подключиться к backend по websocket для оперативного обновления логов.
     */
    async connectToLogsWebsocket(context) {
      await context.dispatch('connectToWebsocket', 'ws/statistic/logs/lm_player', { root: true })
      .then( (wsConn) => {
        context.state.ws_connection_to_logs = wsConn
        context.state.ws_connection_to_logs.onmessage = (event) => {
          context.commit('addLog', event.data)
        }
      })
    },
    /**
     * Отключиться от backend по websocket для оперативного обновления логов.
     */
    async disconnectFromLogsWebsocket(context) {
      if ('ws_connection_to_logs' in context.state) {
        context.state.ws_connection_to_logs.close()
      }
    },
  },
  getters: {
  },
};