<template>
  <svg viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.03448 24H0V20.6102H3.03448V24" :fill="color"/>
    <path d="M8.27585 24H5.24136V15.4577H8.27585V24" :fill="color"/>
    <path d="M13.5172 24H10.4828V10.3051H13.5172V24Z" :fill="color"/>
    <path d="M18.7586 24H15.7242V5.15256H18.7586V24Z" :fill="color"/>
    <path d="M24 24H20.9655V-4.15158e-07H24V24" fill="#DBDBDB"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>




