<template>
  <v-navigation-drawer
    :value="drawer"
    app
    color="#f0f0f0"
  >
    <v-list-item>
      <v-card
        class="mx-auto mt-5"
        max-width="220px"
        height = "170px"
        flat
        justify=center
        align=center
        color="#f0f0f0"
      >
        <v-img
          max-width="180px"
          contain
          src= '@/assets/ls_player_logo.svg'
        ></v-img>
        <div align=start class="ml-6">
          <v-card-title> {{ object.name }} <br/></v-card-title>
          <v-card-subtitle>{{ object.place }}</v-card-subtitle>
        </div>
      </v-card>
    </v-list-item>
    <v-divider></v-divider>
    <!-- <v-img
    src="/300?random"
    /> -->
    <v-list nav expand>
      <v-list-group
        v-for="(item,i) in menuGroups"
        :key="i"
        v-model="item.active"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(subItem,i) in item.items"
          :key="i"
          dense
          class='pl-5'
          :to="getLink(subItem.link)"
          v-show="checkVisibility(subItem)"
        >
          <v-list-item-icon class="mr-1">
            <v-icon small>mdi-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t(subItem.title)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>


  </v-navigation-drawer>
</template>

<script>
import { localizeRoute } from '@/i18n'


export default {
  name: "Sidebar",
  data: () => ({
    menuGroups: [
      {
        icon: 'mdi-home',
        title: "sidebar.menu.statistics.title",
        items: [ {name: 'home',title: 'sidebar.menu.statistics.items.home', link: 'home'}, ],
        active: true
      },
      {
        icon: 'mdi-brush',
        title: "sidebar.menu.playing.title",
        items: [
          {name: 'cues', title: 'sidebar.menu.playing.items.cues', link: 'cues'},
          {name: 'playlists', title: 'sidebar.menu.playing.items.playlists', link: 'playlists'},
          {name: 'scheduler', title: 'sidebar.menu.playing.items.scheduler', link: 'scheduler'},
          {name: 'player', title: 'sidebar.menu.playing.items.player', link: 'manual_player'},
          {name: 'triggers', title: 'sidebar.menu.playing.items.triggers', link: 'triggers',},
        ],
        active: true
      },
      {
        icon: 'mdi-settings',
        title: "sidebar.menu.settings.title",
        items: [
          {name: 'main', title: 'sidebar.menu.settings.items.main', link: 'main_settings'},
          {name: 'universes_and_devices', title: 'sidebar.menu.settings.items.universes_and_devices', link: 'universes_and_devices'},
          {name: 'datetime', title: 'sidebar.menu.settings.items.datetime', link: 'datetime_settings'},
          {name: 'network', title: 'sidebar.menu.settings.items.network', link: 'network_settings'},
          {name: 'security', title: 'sidebar.menu.settings.items.security', link: 'security_settings'},
          {name: 'users', title: 'sidebar.menu.settings.items.users', link: 'users_settings'},
          {name: 'update', title: 'sidebar.menu.settings.items.update', link: 'update'},
          {name: 'backup_and_restore', title: 'sidebar.menu.settings.items.backup_and_restore', link: 'backup_and_restore'},
          {name: 'license', title: 'sidebar.menu.settings.items.license', link: 'license_info'},
        ],
        active: true
      },
    ],
  }),
  computed: {
    drawer() {
      return this.$store.state.drawer
    },
    object () {
      return this.$store.getters['mainSettings/getPlace']
    },
    /**
     * @return {string} Возвращает текущий режим работы плеера.
     */
    workingMode() {
      return this.$store.getters['mainSettings/getCurrentMode']
    },
  },
  methods: {
    checkVisibility(item) {
      console.log(this.workingMode)
      if (this.workingMode == 'cmt') {
        if (item.name == 'playlists') {return false}
        else if (item.name == 'scheduler') {return false}
        else if (item.name == 'player') {return false}
        else if (item.name == 'triggers') {return false}
      }
      if (this.workingMode == 'manual') {
        if (item.name == 'scheduler') {return false}
      }
      if (this.workingMode == 'scheduler') {
        if (item.name == 'player') {return false}
        else if (item.name == 'triggers') {return false}
      }
      return true
    },
    /**
     * Локализирует маршрут.
     * @param {string} link 
     */
    getLink(link) {
      let route = localizeRoute({name: link})
      return route
    },
  },
}
</script>

<style>
</style>