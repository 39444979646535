import Api from "@/api/api";

export default {
  namespaced: true,
  state: {
    modeValues: [
      {'title': 'work_mode.cmt_mode', 'value': 'cmt'},
      {'title': 'work_mode.manual_mode', 'value': 'manual'},
      {'title': 'work_mode.scheduler_mode', 'value': 'scheduler'},
    ],
    mainSettingsFilter: [
      "object_name",
      "object_place",
      "mode",
      "fps",
    ],
    settings: [
      {"key":"fps", "value":"40", "comment":"Frame per second", "readonly": false},
      {"key":"mode","value":"manual","comment":"lightmaster's working mode","readonly": false}
    ],
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    updateSetting(state, setting) {
      state.settings = state.settings.map((o) => {
        return o.key === setting.key ? setting : o;
      });
    },
  },
  actions: {
    async getSettings({ commit }) {
      await Api.loadSettings().then((settings) => {
        commit("setSettings", settings);
      });
    },
    async saveSetting({ commit }, setting) {
      await Api.saveSetting(setting).then((setting) => {
        commit("updateSetting", setting);
      });
    },
  },
  getters: {
    getModeValues(state) {
      return state.modeValues
    },
    getCurrentMode (state) {
      for (let item of state.settings) {
        if (item.key == 'mode') {
          return item.value
        }
      }
    },
    getLabelOfCurrentMode (state, getters) {
      for (let mode of state.modeValues) {
        if (mode.value == getters.getCurrentMode) {
          return mode.title
        }
      }
    },
    getSettings(state) {
      let projSettings = {}
      for (let setting of state.mainSettingsFilter) {
        for (let main_setting of state.settings) {
          if (setting === main_setting.key) {
            projSettings[setting] = main_setting
          }
        }
      }
      return projSettings
    },
    getPlace(state) {
      let obj = {
        name: "",
        address: "",
      }
      let dummy = state.settings.find(i=>i.key=="object_name")
      if (dummy!=undefined)
        obj.name = dummy.value
      
      dummy = state.settings.find(i=>i.key=="object_place")
      if (dummy!=undefined)
        obj.place = dummy.value

      return obj
    },
    /**
       * Получить текущее значение fps.
       * @returns {number} Значение fps.
       */
    getFps(state) {
      for (let setting of state.settings) {
        if (setting.key === "fps") {
          return setting.value
        }
      }
      return 0.025
    },
  },
};
