<template>
  <div>
    <v-menu
      top
      :offset-y="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          {{ currentLocaleName }}
        </div>
      </template>

      <v-list>
        <v-list-item
          v-for="(locale, index) in locales"
          :key="index"
          link
        >
          <v-list-item-title @click="switchLocale(locale.value)">{{ locale.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { loadLanguageAsync } from '@/i18n'

export default {
    name: "LocaleSwitcher",
    data: () => ({
    }),
    methods: {
      /**
       * Меняет локаль.
       * @param {string} locale Название локали.
       */
      switchLocale(locale) {
        loadLanguageAsync(locale).then(() =>{
          let to = this.$router.resolve({ params: {locale} })
          this.$router.push(to.location)
        })
      },
    },
    computed: {
      /**
       * @returns Возвращает список возможных локалей.
       */
      locales () {
        return this.$store.state.supportedLocales
      },
      /**
       * @returns {string} Возвращает текущую локаль.
       */
      currentLocaleName() {
        let idx = this.locales.findIndex((item) => item.value === this.$i18n.locale)
        return this.locales[idx].name
      }
    },
}
</script>

<style>

</style>