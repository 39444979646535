import Api from "@/api/api";
import moment from 'moment';

export default {
  namespaced: true,
  state: {
    licenseInfo: {
    },
  },
  mutations: {
    setLicenseInfo(state, licenseInfo) {
      state.licenseInfo = licenseInfo
    },
  },
  actions: {
    async getLicenseInfo({ commit }) {
      await Api.getLicenseInfo().then((licenseInfo) => {
        commit("setLicenseInfo", licenseInfo)
      })
    },
  },
  getters: {
    getExpiredDate(state) {
      if (state.licenseInfo.CLIENT !== undefined) {
        return moment(state.licenseInfo.EXPIRED_DATE, "YYYY-MM-DD")
      } else {
        return moment("1970-01-01", "YYYY-MM-DD")
      }
    },
    isDemoLicense(state) {
      return state.licenseInfo.CLIENT === 'DEMO' || state.licenseInfo.CLIENT === undefined
    },
    isExpiredLicense(_, getters) {
      return getters.licenseDayLeft < 1
    },
    licenseDayLeft(_, getters) {
      var days_left = moment(getters.getExpiredDate).diff(new Date(), 'days')
      return days_left
    },
    isLicenseValid(_, getters) {
      return !getters.isExpiredLicense && !getters.isDemoLicense
    },
  },
};
