import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import TransitionOutOffIcon from "@/components/icons/TransitionOutOffIcon.vue"
import TransitionOutOnIcon from "@/components/icons/TransitionOutOnIcon.vue"
import TransitionInOffIcon from "@/components/icons/TransitionInOffIcon.vue"
import TransitionInOnIcon from "@/components/icons/TransitionInOnIcon.vue"
import TransitionOutOffNewIcon from "@/components/icons/TransitionOutOffNewIcon.vue"
import TransitionOutOnNewIcon from "@/components/icons/TransitionOutOnNewIcon.vue"
import TransitionInOffNewIcon from "@/components/icons/TransitionInOffNewIcon.vue"
import TransitionInOnNewIcon from "@/components/icons/TransitionInOnNewIcon.vue"
import FadeInIcon from "@/components/icons/FadeInIcon.vue"
import FadeOutIcon from "@/components/icons/FadeOutIcon.vue"
import NoSignalIcon from "@/components/icons/signal/NoSignalIcon.vue"
import VeryLowSignalIcon from "@/components/icons/signal/VeryLowSignalIcon.vue"
import LowSignalIcon from "@/components/icons/signal/LowSignalIcon.vue"
import MiddleSignalIcon from "@/components/icons/signal/MiddleSignalIcon.vue"
import GoodSignalIcon from "@/components/icons/signal/GoodSignalIcon.vue"
import FullSignalIcon from "@/components/icons/signal/FullSignalIcon.vue"


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#c84917',
      },
    },
  },
  icons: {
    values: {
      TransitionOutOffIcon: {
        component: TransitionOutOffIcon
      },
      TransitionOutOnIcon: {
        component: TransitionOutOnIcon
      },
      TransitionInOffIcon: {
        component: TransitionInOffIcon
      },
      TransitionInOnIcon: {
        component: TransitionInOnIcon
      },
      TransitionOutOffNewIcon: {
        component: TransitionOutOffNewIcon
      },
      TransitionOutOnNewIcon: {
        component: TransitionOutOnNewIcon
      },
      TransitionInOffNewIcon: {
        component: TransitionInOffNewIcon
      },
      TransitionInOnNewIcon: {
        component: TransitionInOnNewIcon
      },
      FadeInIcon: {
        component: FadeInIcon
      },
      FadeOutIcon: {
        component: FadeOutIcon
      },
      NoSignalIcon: {
        component: NoSignalIcon
      },
      VeryLowSignalIcon: {
        component: VeryLowSignalIcon
      },
      LowSignalIcon: {
        component: LowSignalIcon
      },
      MiddleSignalIcon: {
        component: MiddleSignalIcon
      },
      GoodSignalIcon: {
        component: GoodSignalIcon
      },
      FullSignalIcon: {
        component: FullSignalIcon
      },
    },
  },
});
