import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/locales/en.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {'en': messages},

  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n экземпляра, так что свойство locale также существует здесь

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  }
})

const loadedLanguages = ['en'] // список пред-загруженных локализаций 
export const supportedLocales = ['en', 'ru'] // список поддерживаемых локализаций

/**
 * Меняет локаль.
 * @param {string} locale Новая локаль.
 * @returns {string} locale Новая локаль.
 */
function setI18nLocale(locale) {
  i18n.locale = locale
  // axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html').setAttribute('lang', locale)
  return locale
}

/**
 * Меняет локаль и лениво загружает нужный словарь если он еще не загружен.
 * @param {string} locale Новая локаль.
 * @returns {Promise} Промис в результате которого будет сменена локаль.
 */
export function loadLanguageAsync(locale) {
  // Проверяем поддерживается ли локаль
  if (!supportedLocales.includes(locale)) return Promise.reject(new Error('Locale not supported'))
  // Если локализация та же
  if (i18n.locale === locale) return Promise.resolve(locale)
  // Если локализация уже была загружена
  if (loadedLanguages.includes(locale)) return Promise.resolve(setI18nLocale(locale))
  // Если локализация ещё не была загружена
  return import(
    `@/locales/${locale}.json`
  ).then(messages => {
    i18n.setLocaleMessage(locale, messages.default || messages)
    loadedLanguages.push(locale)
    return setI18nLocale(locale)
  })
}
/**
 * Локализирует маршрут добавляя к нему значение локали.
 * @param {*} route Не локализированный маршрут.
 * @returns Локализованный маршрут
 */
export function localizeRoute(route) {
  return {
    ...route,
    params: {locale: i18n.locale, ...route.params} 
  }
}