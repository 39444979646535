import Vue from "vue";
import Vuex from "vuex";
import Api from "@/api/api";
import time from './modules/time'
import license from './modules/license'
import update from './modules/update'
import notification from './modules/notification'
import trigger from './modules/trigger'
import mainSettings from './modules/main_settings'
import externalAccess from './modules/external_access'
import certificates from './modules/certificates'
import network from './modules/network'
import logs from './modules/logs'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    time,
    license,
    update,
    notification,
    trigger,
    mainSettings,
    externalAccess,
    certificates,
    network,
    logs,
  },
  state: {
    /**
     * Поддерживаемые локали.
     */
    supportedLocales: [
      {name: 'English', value: 'en'},
      {name: 'Русский', value: 'ru'},
    ],
    /// Authentication
    token: localStorage.getItem('lm_token'),
    /// Authorization
    userProfile: {
      'username': '',
      'role': ''
    },
    users: [],
    // MEDIA Path
    mediaPath: process.env.VUE_APP_MEDIA_PATH || `${window.location.protocol}//${window.location.host}/media/`,
    drawer: true,
    version: '',
    subversion: '',
    networkModeOptions: ['unicast', 'broadcast'],
    artNetDevices: [],
    universes_settings: [],
    cues: [],
    events: [],
    playlists: [],
    // Cue Upload
    uploadProgress: 0,
    //
    login: false,
    tryLogin: false,
    tmpEvent: {},
    dev_mode: false,
    play_button: true,
    stop_button: true,
    pause_button: true,
    playingEntities: [],
    playingInfo: {
      "cue": null,
      "playlist": null,
      "scene": null,
    },
    // WebSocket
    /////////////
    wsConnectionCounter: 0,
    wsConnectionArray: [],
  },
  mutations: {
    /// Authentication
    updateToken(state, token) {
      localStorage.setItem('lm_token', token)
      state.token = token
    },
    removeToken(state) {
      localStorage.removeItem('lm_token')
      state.token = null
    },
    /// Authorization
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile
    },
    removeUserProfile(state) {
      state.userProfile = {
        'username': '',
        'role': ''
      }
    },
    setUsers(state, users) {
      state.users = users
    },
    ///
    resetRefreshing(state) {
      state.refreshing = false;
      state.bad_status = false;
    },
    enterRefresh(state) {
      state.refreshing = true;
    },
    exitRefreshing(state, status) {
      state.refreshing = false;
      if (status != "OK") {
        state.bad_status = true;
      }
    },
    addUniverse(state, universe) {
      state.universes_settings.push(universe)
    },
    editUniverse(state, universe) {
      var idx = state.universes_settings.findIndex((item) => item.number == universe.number);
      state.universes_settings.splice(idx, 1, universe)
    },
    deleteUniverse(state, universe) {
      var idx = state.universes_settings.findIndex((item) => item.number == universe.number);
      state.universes_settings.splice(idx, 1)
    },
    setUniversesSettings(state, settings) {
      state.universes_settings = settings
    },
    setArtNetDevicesSettings(state, settings) {
      state.artNetDevices = settings
    },
    addArtNetDevice(state, device) {
      state.artNetDevices.push(device)
    },
    editArtNetDevice(state, device) {
      //state.artNetDevices = state.artNetDevices.map((o) => {
      //  return o.name === device.name ? device : o;
      //});
      var idx = state.artNetDevices.findIndex((item) => item.name == device.name);
      state.artNetDevices.splice(idx, 1, device)
    },
    deleteArtNetDevice(state, deviceName) {
      var idx = state.artNetDevices.findIndex((item) => item.name == deviceName);
      state.artNetDevices.splice(idx, 1);
    },
    // Scheduler
    setEvents(state, events) {
      state.events = events;
    },
    addEvent(state, event) {
      state.events.push(event);
    },
    deleteEvent(state, id) {
      var idx = state.events.findIndex((item) => item.id == id);
      state.events.splice(idx, 1);
    },
    updateEvent(state, event) {
      state.events = state.events.map((o) => {
        return o.id === event.id ? event : o;
      });
    },
    // Cues
    setCues(state, cues) {
      cues.map((i) => {
        i.filename = i.filename.split("/").pop();
        return i;
      });
      state.cues = cues;
    },
    addCue(state, cue) {
      state.cues.push(cue);
    },
    deleteCue(state, id) {
      var idx = state.cues.findIndex((item) => item.id == id);
      state.cues.splice(idx, 1);
    },
    updateCue(state, cue) {
      state.cues = state.cues.map((o) => {
        return o.id === cue.id ? cue : o;
      });
    },
    // Upload Cue
    updateUploadProgress(state, uploadProgress) {
      state.uploadProgress = uploadProgress
    },
    // Playlists
    setPlaylists(state, playlists) {
      state.playlists = playlists;
    },
    addPlaylist(state, playlist) {
      state.playlists.push(playlist);
    },
    deletePlaylist(state, id) {
      var idx = state.playlists.findIndex((item) => item.id == id);
      state.playlists.splice(idx, 1);
    },
    updatePlaylist(state, playlist) {
      state.playlists = state.playlists.map((o) => {
        return o.id === playlist.id ? playlist : o;
      });
    },
    ////
    // Scenes
    addScene(state, scene) {
      var playlist = state.playlists.find((i) => {
        return i.id == scene.playlist;
      });
      playlist.scenes.push(scene);
    },
    deleteScene(state, scene) {
      var playlist = state.playlists.find((i) => {
        return i.id == scene.playlist;
      });
      var idx = playlist.scenes.findIndex((i) => i.id == scene.id);

      playlist.scenes.splice(idx, 1);
    },
    updateScene(state, scene) {
      var playlist = state.playlists.find((i) => {
        return i.id == scene.playlist;
      });
      playlist.scenes = playlist.scenes.map((o) => {
        return o.id === scene.id ? scene : o;
      });
    },
    updateScenes(state, {scenesList, playlistId}) {
      let playlist = state.playlists.find((i) => {
        return i.id == playlistId;
      });
      playlist.scenes = scenesList
    },
    enablePlayButton(state) {
      state.play_button = false
      state.stop_button = true
      state.pause_button = true
    },
    enableStopButton(state) {
      state.play_button = true
      state.stop_button = false
      state.pause_button = true
    },
    enablePauseButton(state) {
      state.play_button = true
      state.stop_button = true
      state.pause_button = false
    },
    setEntities(state) {
      Api.getPlayingEntities().then(
        (entities) => state.playingEntities = entities
      )
    },
    setPlayingInfo(state, info) {
      Object.assign(state.playingInfo, info)
    },
    setManCmtVisibility(state) {
      state.menu_groups[1].items[1].visible = false // off Playlists
      state.menu_groups[1].items[2].visible = false // off Schedule
      state.menu_groups[1].items[3].visible = false // off Player
    },
    setVersions(state, versions) {
      state.version = versions.version
      state.subversion = versions.subversion
    },
    changeDrawer(state) {
      state.drawer = !state.drawer
    },
    ////////////////////
    // WebSocket
    ////////////
    increaseWsConnectionCounter(state) {
      state.wsConnectionCounter++
    },
    decreaseWsConnectionCounter(state) {
      state.wsConnectionCounter--
    },
    addToWsConnectiontoArray(state, wsUrl) {
      state.wsConnectionArray.push(wsUrl)
    },
    deleteFromWsConnectiontoArray(state, wsUrl) {
      let idx = state.wsConnectionArray.indexOf(wsUrl)
      state.wsConnectionArray.splice(idx, 1);
    },
  },
  actions: {
    /// Authentication
    async login({ commit }, credentials) {
      await Api.login(credentials).then( ({ auth_token }) => {
        commit('updateToken', auth_token)
      })
    },
    async logout({ commit }) {
      await Api.logout().then(() => {
        commit('removeToken')
        commit('removeUserProfile')
      })
    },
    async resetPassword(context, {currentPassword, newPassword, repeatedNewPassword}) {
      let passwords = {
        "current_password": currentPassword,
        "new_password": newPassword,
        're_new_password': repeatedNewPassword,
      }
      await Api.resetPassword(passwords)
    },
    /// Authorization
    async getUserProfile({ commit }) {
      await Api.getUserProfile().then((userProfile) => {
        commit('setUserProfile', userProfile)
      })
    },
    /// Users Management
    async getUsers({ commit }) {
      await Api.getUsers().then( users => {
        commit('setUsers', users)
      })
    },
    async changeUserPassword(context, {id, data}) {
      let passwords = {
        "new_password": data.newPassword,
        're_new_password': data.repeatedNewPassword,
      }
      await Api.changeUserPassword(id, passwords)
    },
    ///
    updateArtNetDevicesSettings({ commit }) {
      Api.loadArtNetDevicesSettings()
      .then( (settings) => {
        commit("setArtNetDevicesSettings", settings)
      })
    },
    createArtNetDevice({ commit }, newDevice) {
      Api.addArtNetDevice(newDevice)
      .then( (device) => {
        commit("addArtNetDevice", device)
      })
    },
    editArtNetDevice({ commit }, device) {
      Api.editArtNetDevice(device)
      .then( (device) => {
        commit("editArtNetDevice", device)
      })
    },
    deleteArtNetDevice({ commit }, deviceName) {
      Api.deleteArtNetDevice(deviceName).then((result) => {
        if (result) commit("deleteArtNetDevice", deviceName);
      });
    },
    updateUniversesSettings({ commit }) {
      Api.loadUniversesSettings()
      .then( (settings) => {
        commit("setUniversesSettings", settings)
      })
    },
    async addUniverse({ commit }, newUniverse) {
      await Api.addUniverse(newUniverse)
      .then( (universe) => {
        commit("addUniverse", universe)
      })
    },
    editUniverse({ commit }, universe) {
      Api.editUniverse(universe)
      .then( (universe) => {
        commit("editUniverse", universe)
      })
    },
    deleteUniverse({ commit }, universe) {
      Api.deleteUniverse(universe).then((result) => {
        if (result) commit("deleteUniverse", universe);
      });
    },
    /// Scheduler
    addEvent({ commit }, newEvent) {
      Api.addEvent(newEvent).then((event) => {
        commit("addEvent", event);
      });
    },
    getEvents({ commit }) {
      Api.getEvents().then((events) => {
        commit("setEvents", events);
      });
    },
    updateEvent({ commit }, event) {
      Api.updateEvent(event).then((editedEvent) => {
        commit("updateEvent", editedEvent);
      });
    },
    deleteEvent({ commit }, id) {
      Api.deleteEvent(id).then((result) => {
        if (result) commit("deleteEvent", id);
      });
    },
    ////
    // Cues
    getCues({ commit }) {
      Api.loadCues().then((cues) => {
        commit("setCues", cues);
      });
    },
    updateCue({ commit }, cue) {
      Api.saveCue(cue).then((cue) => {
        commit("updateCue", cue);
      });
    },
    deleteCue({ commit }, id) {
      Api.deleteCue(id).then((result) => {
        if (result) commit("deleteCue", id);
      });
    },
    refreshCues({ commit }) {
      Api.refreshCues().then((cues) => {
        commit("setCues", cues);
      });
    },
    ////////////////
    /// Playlists
    createPlaylist({ commit }, newPlaylist) {
      Api.createPlaylist(newPlaylist).then((playlist) => {
        commit("addPlaylist", playlist);
      });
    },
    getPlaylists({ commit }) {
      Api.loadPlaylists().then((playlists) => {
        commit("setPlaylists", playlists);
      });
    },
    updatePlaylist({ commit }, playlist) {
      Api.savePlaylist(playlist).then((playlist) => {
        commit("updatePlaylist", playlist);
      });
    },
    deletePlaylist({ commit }, id) {
      Api.deletePlaylist(id).then((result) => {
        if (result) commit("deletePlaylist", id);
      });
    },
    ////////////////
    /// Scenes
    createScene({ commit }, newScene) {
      Api.createScene(newScene).then((scene) => {
        commit("addScene", scene);
      });
    },
    async deleteScene({ commit }, scene) {
      await Api.deleteScene(scene.id).then((result) => {
        if (result) commit("deleteScene", scene);
      });
    },
    attachCueToScene({ commit }, { seqId, scene, cueId }) {
      if (cueId == undefined) cueId = null;
      scene.cue = cueId;

      Api.updateScene(scene).then((scene) => {
        commit("updateScene", { seqId, scene });
      });
    },
    updateScenes({ commit }, {scenesList, playlistId}) {
      for (let scene of scenesList) {
        Api.updateScene(scene)}
      commit("updateScenes", {scenesList: scenesList, playlistId})
    },
    changeScene({ commit }, scene) {
      Api.updateScene(scene).then((scene) => {
        commit("updateScene", scene);
      });
    },
    loadPlayingEntities({ commit }) {
      Api.getPlayingEntities().then((entities) => {
        commit("setEntities", entities);
      });
    },
    getPlayingInfo({ commit }) {
      Api.getPlayingInfo().then((info) => {
        commit("setPlayingInfo", info);
      });
    },
    ////////////////
    /// version
    getVersions({ commit }) {
      Api.getVersions().then((versions) => {
        commit('setVersions', versions)
      })
    },
    ////////////////
    // Websocket
    ////////////
    connectToWebsocket(context, url) {
      // Connect to ws
      //
      // Attrs:
      //    url - ws url.
      //
      // Returns:
      //    WebSocket
      let reconnectUrl = url

      let wsProtocol = ''
      if (window.location.protocol == 'https:') {
        wsProtocol = 'wss'
      } else {
        wsProtocol = 'ws'
      }
      let wsUrl = process.env.VUE_APP_WS_URL ? `${process.env.VUE_APP_WS_URL}/${url}` : `${wsProtocol}://${window.location.host}/${url}`
      console.log(`Starting connection to WebSocket Server ${wsUrl}`)
      let wsConnection = new WebSocket(wsUrl)
      wsConnection.onmessage = (event) => {
        console.log(`${event} was received via websocket`)
      }
      wsConnection.onopen = () => {
        console.log(`Successfully connected to the echo websocket ${wsUrl}`)
        context.commit('increaseWsConnectionCounter')
        context.commit('addToWsConnectiontoArray', wsUrl)
      }
      wsConnection.onclose = (e) => {
        context.commit('decreaseWsConnectionCounter')
        context.commit('deleteFromWsConnectiontoArray', wsUrl)
        if (e.code == 1000) {
          console.log(`Disconnected from the echo websocket ${wsUrl}`)
        } else {
          console.log('Socket is closed. Reconnect will be attempted in 5 second.', e.reason)
          setTimeout(() => context.dispatch('connectToWebsocket', reconnectUrl), 5000)
        }
      }
      return wsConnection
    },
  },
  getPlaylists({ commit }) {
    Api.loadPlaylists().then((playlists) => {
      commit("setPlaylists", playlists);
    });
  },
  getters: {
    /// Authorization
    isOperator(state) {
      return state.userProfile.role === 'operator' || state.userProfile.role === 'administrator'
    },
    isAdministrator(state) {
      return state.userProfile.role === 'administrator'
    },
    isLogin(state) {
      return state.login
    },
    errLogin(state) {
      return state.tryLogin
    },
    getPlayingEntities(state) {
      return state.playingEntities
    },
    getDevModeStatus (state) {
      return state.dev_mode
    },
    getUniversesSettings(state) {
      return state.universes_settings
    },
    getArtNetDevicesSettings(state) {
      return state.artNetDevices
    },
    getNetworkModeOptions(state) {
    return state.networkModeOptions
    },
  },
});
