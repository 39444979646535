import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { i18n, loadLanguageAsync, supportedLocales } from "@/i18n"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: `${i18n.locale}/home`
  },
  {
    path: '/:locale',
    component: {
      render(h) {
          return h("router-view");
      }
    },
    /**
     * Отслеживает смену локали в url при первом доступе.
     * Меняет локаль если она поменяна в маршруте.
     */
    beforeEnter: (to, from, next) => {
      let locale = to.params.locale
      if (!supportedLocales.includes(locale)) {return next('en')}
      
      if (i18n.locale !== locale) {
        loadLanguageAsync(locale).then(() => {return next()})
      } else {
        return next()
      }
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login.vue')
      },  
      {
        path: 'scheduler',
        name: 'scheduler',
        component: () => import('@/views/Scheduler.vue')
      },
      {
        path: 'cues',
        name: 'cues',
        component: () => import('@/views/Cues.vue')
      },
      {
        path: 'playlists',
        name: 'playlists',
        component: () => import('@/views/Playlists.vue')
      },
      {
        path: 'main_settings',
        name: 'main_settings',
        component: () => import('@/views/Settings.vue')
      },
      {
        path: 'all-settings',
        name: 'Настройки все',
        component: () => import('@/views/AllSettings.vue')
      },
      {
        path: 'manual_player',
        name: 'manual_player',
        component: () => import('@/views/Manmode.vue')
      },
      {
        path: 'triggers',
        name: 'triggers',
        component: () => import('@/views/Triggers.vue')
      },
      {
        path: 'universes_and_devices',
        name: 'universes_and_devices',
        component: () => import('@/views/UniversesSettings.vue')
      },
      {
        path: 'network_settings',
        name: 'network_settings',
        component: () => import('@/views/NetworkSettings.vue')
      },
      {
        path: 'security_settings',
        name: 'security_settings',
        component: () => import('@/views/ExternalAccessSettings.vue')
      },
      {
        path: 'datetime_settings',
        name: 'datetime_settings',
        component: () => import('@/views/DatetimeSettings.vue')
      },
      {
        path: 'users_settings',
        name: 'users_settings',
        component: () => import('@/views/UsersSettings.vue')
      },
      {
        path: 'backup_and_restore',
        name: 'backup_and_restore',
        component: () => import('@/views/BackupAndRestore.vue')
      },
      {
        path: 'license_info',
        name: 'license_info',
        component: () => import('@/views/License.vue')
      },
      {
        path: 'update',
        name: 'update',
        component: () => import('@/views/Update.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

/**
 * Переадресовывает не авторизированных пользователей на страницу логина.
 */
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.state.token) {
    next({ name: 'login', params: {locale: i18n.locale} })
  }
  else {next()}
})

export default router
