<template>
  <div>
  </div>
</template>

<script>
import 'moment-timezone'
import moment from 'moment'

export default {
  methods: {
    connectToNotificationWebsocket() {
      // Connect to ws and update notifications into the store
      //
      this.$store.dispatch('connectToWebsocket', 'ws/notification').then( (wsConn) => {
        this.ws_connection_to_notification = wsConn
        this.ws_connection_to_notification.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            console.log(serialized_msg)
            this.$store.commit('notification/addNotification', serialized_msg)
            this.$store.commit('notification/addPushNotification', serialized_msg)
          })
        }
      })
    },
    connectToVersionListWebsocket() {
      // Connect to ws and update version_list to the store
      //
      this.$store.dispatch('connectToWebsocket', 'ws/version/version_list').then( (wsConn) => {
        this.ver_list_ws_connection = wsConn
        this.ver_list_ws_connection.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            this.$store.commit('update/setVersionList', serialized_msg)
          })
        }
      })
    },
  },
  created () {
    this.$store.dispatch('time/getLightmasterTimezone').then(() => {
        moment.tz.setDefault(this.$store.state.time.lightmasterTimezone)
    })
    this.$store.dispatch('getUserProfile')
    this.$store.dispatch('license/getLicenseInfo')
    this.$store.dispatch('time/getSystemTime')
    this.$store.dispatch('mainSettings/getSettings')
    this.$store.dispatch('externalAccess/getWebAccessSettings')
    this.$store.dispatch('certificates/getCertificates')
    // Запрашивает обновление информации об modem интерфейсе с backend
    this.$store.dispatch('network/getIfaceModem')
    // Запрашивает обновление информации об wired интерфейсе с backend
    this.$store.dispatch('network/getIfaceWired')

    this.connectToVersionListWebsocket()
    this.connectToNotificationWebsocket()
  },
  destroyed() {
    this.ver_list_ws_connection.close()
    this.ws_connection_to_notification.close()
  },
}
</script>

