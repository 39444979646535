import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import './plugins//vee-validate';

Vue.config.productionTip = false

import 'typeface-roboto'
import  '@mdi/font/css/materialdesignicons.min.css'
import { i18n }  from '@/i18n'


new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);