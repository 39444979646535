import Axios from 'axios'
import store from '@/store'
import router from '@/router'
import { localizeRoute } from '@/i18n'

export const HTTP = Axios.create({
  baseURL: process.env.VUE_APP_API_URL || '/api',
})

HTTP.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('lm_token')

    if (token) {
      config.headers['Authorization'] = `Token ${ token }`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)


HTTP.interceptors.response.use(
  (response) => {
    return response
  },

  (error) => {
    if (error.response.status === 401) {
      store.commit('removeToken')
      store.commit('removeUserProfile')
      router.push(localizeRoute({name: 'login'}))
    }
    return Promise.reject(error)
  }
)