import Api from "@/api/api";

export default {
  namespaced: true,
  state: {
    versionList: [
      {
        "id": 1,
        "version": "0",
        "subversion": null,
        "module": "general",
        "description": null
      },
    ],
    updates_list: [],
  },
  mutations: {
    setNewStatusToUpdate(state, {update, status}) {
      for (let update_obj of state.updates_list) {
        if (update_obj.id == update.id) {
          update_obj.status = status
        }
      }
    },
    setUpdateList(state, updateList) {
      state.updates_list = updateList
    },
    setVersionList(state, versionList) {
      state.versionList = versionList
    },
  },
  actions: {
    async checkUpdate({ commit }, update) {
      await Api.checkUpdate(update).then( () => {
        let status = 'checking'
        commit('setNewStatusToUpdate', {update, status})
      })
    },
    async installUpdate({ commit }, update) {
      await Api.installUpdate(update).then( () => {
        let status = 'installing'
        commit('setNewStatusToUpdate', {update, status})
      })
    },
    async restoreUpdate({ commit }, update) {
      await Api.restoreUpdate(update).then( () => {
        let status = 'restoring'
        commit('setNewStatusToUpdate', {update, status})
      })
    },
    async deleteUpdate({ commit }, update) {
      await Api.deleteUpdate(update).then( () => {
        let status = 'deleting'
        commit('setNewStatusToUpdate', {update, status})
      })
    },
  },
  getters: {
    getCurrentGeneralVersion(state) {
      for (const version of state.versionList) {
        if (version.module === 'general') {
          return version.version
        }
      }
      return '0.0.0'
    },
    checkIngState(state) {
      // Проверяет находится ли какое либо обновление в промежуточном состоянии
      for (let update of state.updates_list) {
        if (update.status.endsWith('ing')) {
          return update.status.endsWith('ing')
        }
      }
      return false
    },
  },
};