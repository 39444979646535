import Api from "@/api/api";
import moment from 'moment';
import 'moment-timezone';

export default {
  namespaced: true,
  state: {
    ntpSyncStatus: false,
    ntpServers: [],
    currentNTPServer: '',
    lightmasterTimezone: '',
    timeMode: '',
    serverTimeOffset: 0,
    actualTime: undefined
  },
  mutations: {
    setSystemTime(state, server_time) {
      state.serverTimeOffset = moment(server_time.time).diff(new Date())
    },
    setNtpServers(state, ntpServers) {
      state.ntpServers = ntpServers
    },
    setNTPSyncStatus(state, syncStatus) {
      state.ntpSyncStatus = syncStatus
    },
    setCurrentNTPServer(state, current_server){
      state.currentNTPServer = current_server
    },
    setLightmasterTimezone(state, timezone) {
      state.lightmasterTimezone = timezone
    },
    setTimeMode(state, timeMode) {
      state.timeMode =timeMode
    },
    /**
     * Запускает timeInterval который обновляет актуальное время каждую секунду
     */
    updateActualTime(state) {
      state.timeUpdateInterval = setInterval( () => {
        state.actualTime = moment(new Date()).add('milliseconds', state.serverTimeOffset).tz(state.lightmasterTimezone)
      }, 1000)
    }
  },
  actions: {
    async getTimeMode({ commit }) {
      await Api.getTimeMode().then(({ time_mode }) => {
        commit("setTimeMode", time_mode)
      })
    },
    async updateTimeMode({ commit }, newTimeMode) {
      await Api.updateTimeMode(newTimeMode).then(({ time_mode }) => {
        commit("setTimeMode", time_mode);
      });
    },
    async getLightmasterTimezone({ commit }) {
      await Api.getLightmasterTimezone().then(({ timezone }) => {
        commit("setLightmasterTimezone", timezone)
      })
    },
    async updateLightmasterTimezone({ commit }, newTimezone) {
      await Api.updateLightmasterTimezone(newTimezone).then(({ timezone }) => {
        commit("setLightmasterTimezone", timezone);
      });
    },
    /**
     * Обновляет системное время с backend
     * и запускает timeInterval для подсчета актуального времени если он еще не запущен.
     */
    async getSystemTime({ commit, state }) {
      await Api.getSystemTime().then((server_time) => {
        commit('setSystemTime', server_time)
        // запустить timeInterval для подсчета актуального времени если он еще не запушен.
        if (!state.timeUpdateInterval) { 
          commit('updateActualTime')
        }
      });
    },
    /**
     * Устанавливает системное время
     * и запускает timeInterval для подсчета актуального времени если он еще не запущен.
     * @param {string} newTime Новое системное время
     */
    async setSystemTime({ commit, state }, newTime) {
      await Api.setSystemTime(newTime).then((server_time) => {
        commit('setSystemTime', server_time)
        // запустить timeInterval для подсчета актуального времени если он еще не запушен.
        if (!state.timeUpdateInterval) {
          commit('updateActualTime')
        }
      })
    },
    async getNTPServers ({ commit }) {
      await Api.getNTPServers().then(({ ntp_servers }) => {
        commit('setNtpServers', ntp_servers)
      })
    },
    async setNTPServers ({ commit }, newNTPServers) {
      await Api.setNTPServers(newNTPServers).then(({ ntp_servers }) => {
        commit('setNtpServers', ntp_servers)
      })
    },
    getNTPSyncStatus ({ commit }) {
      Api.getNTPSyncStatus().then(({ ntp_synchronized }) => {
        commit('setNTPSyncStatus', ntp_synchronized)
      })
    },
    getCurrentNTPServer ({ commit }) {
      Api.getCurrentNTPServer().then(({ current_ntp_server }) => {
        commit('setCurrentNTPServer', current_ntp_server)
      })
    },
  },
  getters: {
    /**
     * Получить актуальное время в формате 'DD.MM.YYYY kk:mm'
     * или пустую строку если актуальное время не задано.
     * @returns {string} Актуальное время.
     */
    getActualTime(state) {
      return state.actualTime ? state.actualTime.format('DD.MM.YYYY kk:mm') : ''
    },
    /**
     * Получить актуальное время в формате 'DD.MM.YYYY kk:mm:ss'
     * или пустую строку если актуальное время не задано.
     * @returns {string} Актуальное время.
     */
    getActualTimeWithSeconds(state) {
      return state.actualTime ? state.actualTime.format('DD.MM.YYYY kk:mm:ss') : ''
    },
  },
};