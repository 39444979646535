<template>
  <v-app id="lightmaster">

    <sidebar v-if="isLogin" />
    <appbar v-if="isLogin" />
    <footerbar v-if="isLogin" />
    <preloads v-if="isLogin" />


    <v-main>
      <v-container>
          <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import sidebar from '@/components/Bars/Sidebar'
import appbar from '@/components/Bars/Appbar'
import footerbar from '@/components/Bars/Footer.vue'
import preloads from '@/components/preloads.vue'

export default {
  computed: {
    isLogin() {
      if (this.$store.state.token) {return true}
      else {return false}
    },
  },
  components: {sidebar, appbar, footerbar, preloads},
}
</script>