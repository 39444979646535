import Api from "@/api/api";

export default {
  namespaced: true,
  state: {
    triggersWithActions: [],
  },
  mutations: {
    setTriggersWtihActions(state, {triggersWithActions}) {
      state.triggersWithActions = triggersWithActions
    },
    addTriggerToTriggersWtihActionsArray(state, newTrigger) {
      state.triggersWithActions.push(newTrigger)
    },
    deleteTriggerFromTriggersWtihActionsArray(state, trigger) {
      let index = state.triggersWithActions.indexOf(trigger)
      state.triggersWithActions.splice(index)
    },
    addActionToTrigger(state, relation) {
      let index = state.triggersWithActions.indexOf(relation.trigger)
      state.triggersWithActions[index].actions.push(relation.action)
    },
    deletActionFromTrigger(state, action) {
      for (let trigger of state.triggersWithActions) {
        let index = trigger.actions.indexOf(action)
        if (index != -1) {
          trigger.actions.splice(index)
        }
      }
    },
  },
  actions: {
    async getAllTriggersWithRelatedActions({ commit }) {
      await Api.getAllTriggersWithRelatedActions().then( (triggersWithActions) => {
        commit('setTriggersWtihActions', {triggersWithActions})
      })
    },
    async createTrigger({ commit }, newTrigger) {
      await Api.createTrigger(newTrigger).then( () => {
        commit('addTriggerToTriggersWtihActionsArray', newTrigger)
      })
    },
    async deleteTrigger({ commit }, trigger) {
      await Api.deleteTrigger(trigger.name).then( () => {
        commit('deleteTriggerFromTriggersWtihActionsArray', trigger)
      })
    },
    async deleteAction({ commit }, action) {
      await Api.deleteAction(action.name).then( () => {
        commit('deletActionFromTrigger', action)
      })
    },
    async createActionRelatedTrigger({ commit }, relation) {
      await Api.createActionRelatedTrigger(relation).then( () => {
        commit('addActionToTrigger', relation)
      })
    },
  },
  getters: {
  },
};