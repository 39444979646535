import Api from "@/api/api";

export default {
  namespaced: true,
  state: {
    certificates: [
    ],
  },
  mutations: {
    setWebCertificates(state, certificates) {
      state.certificates = certificates
    },
    deleteCertificate(state, certificate) {
      let idx = state.certificates.findIndex((item) => item.name == certificate.name);
      state.certificates.splice(idx, 1)
    },
  },
  actions: {
    async getCertificates({ commit }) {
      await Api.getCertificates().then((certificates) => {
        commit("setWebCertificates", certificates)
      })
    },
    async generateSelfSignCertificate(_, certRequest) {
      await Api.generateSelfSignCertificate(certRequest)
    },
    async generateCsr(_, certRequest) {
      await Api.generateCsr(certRequest)
    },
    async deleteCertificate({ commit }, certificate) {
      commit("deleteCertificate", certificate)
      await Api.deleteCertificate(certificate)
    },
    async uploadNewCertificate(_, uploadCertRequest) {
      await Api.uploadNewCertificate(uploadCertRequest)
    },
    async uploadCertificateCorCsr(_, uploadCertCorCsrRequest) {
      await Api.uploadCertificateCorCsr(uploadCertCorCsrRequest)
    },
  },
  getters: {
    getCsrCertificates(state) {
      let csrCertificates = []
      for (let certificate of state.certificates) {
        if (certificate.cert_type == 'csr') {
          csrCertificates.push(certificate)
        }
      }
      return csrCertificates
    },
    getValidCertificates(state) {
      let validCertificates = []
      for (let certificate of state.certificates) {
        if (certificate.cert_type == 'certificate') {
          validCertificates.push(certificate)
        }
      }
      return validCertificates
    },
    isCertNameUniq: (state) => (certName) => {
      for (let certificate of state.certificates) {
        if (certificate.name == certName) {
          return false
        }
      }
      return true
    },
  },
};
