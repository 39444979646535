import Api from "@/api/api";


/**
 * Сериализует параметры web доступа полученные от бекенда.
 * Превращает snake case в camel case.
 * @param {webAccessSettings} webAccessSettings snake case параметры.
 * @returns camel case параметры 
 */
function serializeWebAccessFromBackend(webAccessSettings) {
  let webAccessSettingsSerialized = {
    isHttpsEnabled: webAccessSettings.is_https_enabled,
    isRedirectHttpToHttpsEnabled: webAccessSettings.is_http_redirected,
    httpPort: webAccessSettings.http_port,
    httpsPort: webAccessSettings.https_port,
    certificateName: webAccessSettings.cert_name,
  }
  return webAccessSettingsSerialized
}

/**
 * Сериализует параметры web доступа для отправки бекенду.
 * Превращает camel case в snake case.
 * @param {webAccessSettings} webAccessSettings camel case параметры .
 * @returns snake case параметры.
 */
function serializeWebAccessToBackend(webAccessSettings) {
  let webAccessSettingsSerialized = {
    is_https_enabled: webAccessSettings.isHttpsEnabled,
    is_http_redirected: webAccessSettings.isRedirectHttpToHttpsEnabled,
    http_port: webAccessSettings.httpPort,
    https_port: webAccessSettings.httpsPort,
    cert_name: webAccessSettings.certificateName,
  }
  return webAccessSettingsSerialized
}

export default {
  namespaced: true,
  state: {
    webAccessSettings: {
      isHttpsEnabled: false,
      isRedirectHttpToHttpsEnabled: false,
      httpPort: 80,
      httpsPort: 443,
      certificateName: '',
    }
  },
  mutations: {
    setWebAccessSettings(state, webAccessSettings) {
      state.webAccessSettings = webAccessSettings
    },
  },
  actions: {
    async getWebAccessSettings({ commit }) {
      await Api.getWebAccessSettings().then((webAccessSettings) => {
        let webAccessSettingsSerialized = serializeWebAccessFromBackend(webAccessSettings)
        commit("setWebAccessSettings", webAccessSettingsSerialized)
      })
    },
    async setWebAccessSettings({ commit }, webAccessSettings) {
      commit("setWebAccessSettings", webAccessSettings)
      let webAccessSettingsSerialized = serializeWebAccessToBackend(webAccessSettings)
      await Api.setWebAccessSettings(webAccessSettingsSerialized)
    },
  },
};
