import Api from "@/api/api";

export default {
  namespaced: true,
  state: {
    isModemPresent: false,
    ifaceModem: {
      "status": "up",
      "ip_assign_method": "manual",
      "ip": "",
      "netmask": "",
      "gateway": "",
      "dns_assign_method": "manual",
      "dns_servers": [],
      "mac_address": "",
      "apn": "",
      "username": "",
      "password": "",
      "connection_mode": "",
      "connection_status": "",
      "signal_quality": ""
    },
    ifaceWired: {
      "status": "up",
      "ip_assign_method": "manual",
      "ip": "",
      "netmask": "",
      "gateway": "",
      "dns_assign_method": "manual",
      "dns_servers": [],
      "mac_address": "",
    }
  },
  mutations: {
    /**
     * Полностью обновить состояние ifaceWired.
     * @param {*} iface Новое состояние ifaceWired.
     */
    setWiredIface(state, iface) {
      state.ifaceWired = iface
    },
    /**
     * Полностью обновить состояние ifaceModem.
     * @param {*} iface Новое состояние ifaceModem.
     */
    setModemIface(state, iface) {
      state.ifaceModem = iface
    },
    /**
     * Обновить состояние настроек ip для ifaceModem или ifaceWired.
     */
    updateIpCredential(state, {iface, newIpCredential}) {
      Object.assign(state[iface], newIpCredential)
    },
    /**
     * Обновить состояние настроек dns для ifaceModem или ifaceWired.
     */
    updateDnsCredential(state, {iface, newDnsCredential}) {
      Object.assign(state[iface], newDnsCredential)
    },
    /**
     * Обновить состояние настроек apn для ifaceModem.
     */
    updateApnCredential(state, newAppSettings) {
      Object.assign(state.ifaceModem, newAppSettings)
    },
    /**
     * Обновить состояние isModemPresent для ifaceModem.
     * Модем может быть не подключен в системе и тогда isModemPresent должен быть false.
     * @param {boolean} status Новое состояние ifaceModem.
     */
    setIfaceModemPresentStatus(state, status) {
      state.isModemPresent = status
    },
  },
  actions: {
    /**
     * Обновляет настройки wired интерфейса.
     */
    async getIfaceWired(context) {
      await Api.getIfaceWired().then((iface) => {
        context.commit('setWiredIface', iface)
      })
    },
    /**
     * Обновляет настройки modem интерфейса.
     */
    async getIfaceModem(context) {
      await Api.getIfaceModem().then((iface) => {
        context.commit('setModemIface', iface)
        context.commit('setIfaceModemPresentStatus', true)
      })
      .catch( error => {
        if (error.response.status === 404) {
          context.commit('setIfaceModemPresentStatus', false)
        }
        throw error
      })
    },
    /**
     * Устанавливает настройки ip для интерфейса.
     */
    async updateIpCredential(context, {iface, newIpCredential}) {
      context.commit('updateIpCredential', {iface, newIpCredential})
      if (newIpCredential.ip_assign_method != context.state[iface].dns_assign_method) {
        context.commit('updateDnsCredential', {
          iface,
          newDnsCredential: {
            dns_assign_method: newIpCredential.ip_assign_method,
            dns_servers: [],
          }
        })
      }
      let newIpCredentialForBackend = {
        ip_assign_method: newIpCredential.ip_assign_method,
      }
      if (newIpCredential.ip_assign_method === 'manual') {
        newIpCredentialForBackend.static_ip = newIpCredential.ip
        newIpCredentialForBackend.static_netmask = newIpCredential.netmask
        newIpCredentialForBackend.static_gateway = newIpCredential.gateway
      }
      if (iface === 'ifaceWired') {
        await Api.updateWiredIpCredential(newIpCredentialForBackend)
      } else if (iface === 'ifaceModem') {
        await Api.updateModemIpCredential(newIpCredentialForBackend)
      }
    },
    /**
     * Устанавливает настройки dns для интерфейса.
     */
    async updateDnsCredential(context, {iface, newDnsCredential}) {
      if (context.state[iface].ip_assign_method == 'manual' && newDnsCredential.dns_assign_method == 'dhcp') {
        return
      }
      context.commit('updateDnsCredential', {iface, newDnsCredential})
      let newDnsCredentialForBackend = {
        dns_assign_method: newDnsCredential.dns_assign_method,
        static_dns_servers: newDnsCredential.dns_servers,
      }
      if (iface === 'ifaceWired') {
        await Api.updateWiredDnsCredential(newDnsCredentialForBackend)
      } else if (iface === 'ifaceModem') {
        await Api.updateModemDnsCredential(newDnsCredentialForBackend)
      }
    },
    /**
     * Устанавливает настройки apn для ifaceModem.
     */
    async updateApnCredential({ commit }, newAppSettings) {
      commit('updateApnCredential', newAppSettings)
      await Api.updateModemApnCredential(newAppSettings)
    },
    /**
     * Устанавливает websocket соединения для получения настроек wired интерфейса бекенда.
     */
    async connectToIfaceWiredSettingsWebsocket(context) {
      await context.dispatch('connectToWebsocket', 'ws/network/interfaces/wired', { root: true })
      .then( (wsConn) => {
        context.state.ws_connection_to_iface_wired_settings = wsConn
        context.state.ws_connection_to_iface_wired_settings.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            context.commit('setWiredIface', serialized_msg)
          })
        }
      })
    },
    /**
     * Закрывает websocket соединения для получения настроек wired интерфейса бекенда.
     */
    async disconnectFromIfaceWiredSettingsWebsocket(context) {
      if ('ws_connection_to_iface_wired_settings' in context.state) {
        context.state.ws_connection_to_iface_wired_settings.close()
      }
    },
    /**
     * Устанавливает websocket соединения для получения настроек modem интерфейса бекенда.
     */
    async connectToIfaceModemSettingsWebsocket(context) {
      await context.dispatch('connectToWebsocket', 'ws/network/interfaces/modem', { root: true })
      .then( (wsConn) => {
        context.state.ws_connection_to_iface_modem_settings = wsConn
        context.state.ws_connection_to_iface_modem_settings.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            context.commit('setModemIface', serialized_msg)
            context.commit('setIfaceModemPresentStatus', true)
          })
        }
      })
    },
    /**
     * Закрывает websocket соединения для получения настроек modem интерфейса бекенда.
     */
    async disconnectFromIfaceModemSettingsWebsocket(context) {
      if ('ws_connection_to_iface_modem_settings' in context.state) {
        context.state.ws_connection_to_iface_modem_settings.close()
      }
    },
  },
  getters: {
    /**
     * Получить список ip адресов интерфейсов.
     * @returns {string[]} Список ip адресов интерфейсов.
     */
    getIpAddresses(state) {
      return [state.ifaceWired.ip, state.ifaceModem.ip]
    },
  },
};