<template>
  <svg viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.03448 24H0V20.6102H3.03448V24" fill="#DBDBDB"/>
    <path d="M8.27585 24H5.24136V15.4577H8.27585V24" fill="#DBDBDB"/>
    <path d="M13.5172 24H10.4828V10.3051H13.5172V24Z" fill="#DBDBDB"/>
    <path d="M18.7586 24H15.7242V5.15256H18.7586V24Z" fill="#DBDBDB"/>
    <path d="M24 24H20.9655V-4.15158e-07H24V24" fill="#DBDBDB"/>
    <path d="M9.27107 3.07357L7.66182 1.49158L5.39418 3.72055L3.12654 1.49158L1.51724 3.07357L3.78488 5.30254L1.51724 7.53205L3.12654 9.11404L5.39418 6.88453L7.66182 9.11404L9.27107 7.53205L7.00342 5.30254L9.27107 3.07357Z" fill="#4D4D4D"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>




