<template>
    <v-footer
      :inset="true"
      app
      height="20"
    >
      <v-row>
        <span class="px-4">{{ $t('footer.version') }}: {{ version }}</span>
        <span v-show="subversion" class="px-4">sub: {{ subversion }}</span>
        <v-spacer></v-spacer>
        <locale-switcher />
        <span class="px-4">&copy; {{ new Date().getFullYear() }}</span>
      </v-row>
    </v-footer>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue"

  export default {
    name: "Footer",
    components: {
      LocaleSwitcher,
    },
    computed: {
      version() {
        return this.$store.getters['update/getCurrentGeneralVersion']
      },
      subversion() {
        return ""
      },
    },
    mounted () {
      this.$store.dispatch('getVersions')
    },
  }
</script>